'use strict';

class ForgotPasswordController {
	constructor(Auth, $state, usSpinnerService, $timeout, Cart) {
		this.usSpinnerService = usSpinnerService;
		this.isDisabled = false;
		this.user = {};
		this.errors = {};
		this.submitted = false;

		this.Auth = Auth;
		this.$state = $state;
		this.Cart = Cart;
		$timeout(() => {
			this.stopSpin('spinner-1');
		})
	}

	submit(form) {
		this.submitted = true;

		if (form.$valid) {
			this.startSpin('spinner-1');
			this.isDisabled = true;
			this.Auth.forgorPassword(this.user.email)
				.then((res) => {
					console.log(res);
					this.successMsg = res.data.data.message;
					this.stopSpin('spinner-1');
				})
				.catch(err => {
					this.errors.other = err.message;
					this.stopSpin('spinner-1');
					this.isDisabled = false;
				});
		}
	}

	startSpin(spinner) {
		this.usSpinnerService.spin(spinner);
	}

	stopSpin(spinner) {
		this.usSpinnerService.stop(spinner);
	}
}

angular.module('windmanagerApp')
	.controller('ForgotPasswordController', ForgotPasswordController);
